import Vue from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { AccessTokenKey } from '@/store/auth/auth.store.constants';
import ChatError from '@/views/apps/chat/constants/chat-errors';
import ChatService from '../services/ChatService';

const chatService = new ChatService({
  getToken: () => Vue.$cookies.get(AccessTokenKey),
});

/**
 * Store module for the chat app.
 * WARNING: Right now chats[targetName].contacts does not hold the current list of users that are present in the room.
 * This needs to be fixed in the future.
 */
export default {
  namespaced: true,
  state: {
    /**
     * Map of 'targetName' vs chat information.
     * 'targetName' might be the full name of a room or the user id (for 1:1 chat).
     * The Shape is:
     * {
     *   chat: string[]; // The array of messageIds
     *   messages: {
     *     [messageID]: {
     *        message: string, // The actual text message
     *        time: number, // timestamp of the sended message
     *        senderId: string, // user id of the sender.
     *        isPending: Boolean, // of internal use. True when the message is being sent.
     *        isFailure: Boolean, // of internal use. True when the message sent failed.
     *     }
     *   },
     *   contactIds: [], // The users that are present in the room (needs fixing).
     *   alreadyConnected: false, // of internal use. True when the user has already been connected to the targetName
     * }
     */
    chats: {},
    /**
     * The map of id vs contact information. It should be moved in the future to a module for user information.
     */
    contacts: {},

    connectionCallbacks: [],
    isConnected: false,

    reconnectionQueue: [],
    recoverSessionHandlers: [],

    isConnecting: false,
    // number of open connections. Or number of components activly using the chat service.
    connections: 0,

    isListeningToPersonalMessages: false,

    // Global individual chat
    isIndividualChatVisible: false,
    individualChatUser: null,
    individualChatUsername: null,
    room: null,
    activeChats: [],
    activeChat: null,
  },
  getters: {
    chat: (state) => (targetName) => {
      if (!state.chats[targetName] || !state.chats[targetName].chat) {
        return [];
      }

      return state.chats[targetName].chat.map((messageID) => (
        state.chats[targetName].messages[messageID]
      ));
    },
    /**
     * Consider moving all the contact information objects to a store module specific for users information.
     */
    contactsInformationMap(state) {
      return state.contacts;
    },
    contacts: (state) => (targetName) => {
      if (!state.chats[targetName] || !state.chats[targetName].contactIds) {
        return [];
      }

      return state.chats[targetName].contactIds.map((contactId) => (
        state.contacts[contactId]
      ));
    },

    // Global individual chat
    isIndividualChatVisible: ({ isIndividualChatVisible }) => isIndividualChatVisible,
    individualChatUser: ({ individualChatUser }) => individualChatUser,
    room: ({ room }) => room,
    individualChatUsername: ({ individualChatUsername }) => individualChatUsername,
  },
  mutations: {
    ADD_CHAT_WINDOW(state, { user, room }) {
      const existingChat = state.activeChats.find(chat => chat.room === room);
      if (existingChat) {
        state.activeChats = [
          existingChat,
          ...state.activeChats.filter(chat => chat.room !== room)
        ];
        return;
      }
  
      const maxWindows = (() => {
        const width = window.innerWidth;
        if (width <= 480) return 1;
        if (width <= 768) return 2;
        if (width <= 1024) return 3;
        return 4;
      })();
  
      if (state.activeChats.length >= maxWindows) {
        state.activeChats = state.activeChats.slice(0, maxWindows - 1);
      }
  
      state.activeChats.unshift({ user, room });
    },
    SET_ACTIVE_CHAT(state, roomId) {
      state.activeChat = roomId;
    },
    REMOVE_CHAT_WINDOW(state, room) {
      state.activeChats = state.activeChats.filter(chat => chat.room !== room);
    },
    addConnection(state, { callback, forceReconnection }) {
      if (!forceReconnection) {
        state.connections += 1;
      }
      state.connectionCallbacks.push(callback);
    },
    removeConnection(state, { callback }) {
      state.connections -= 1;
      const index = state.connectionCallbacks.findIndex((f) => f === callback);
      state.connectionCallbacks.splice(index, 1);
    },

    connectionPending(state) {
      state.isConnecting = true;
    },
    connectionSuccessfull(state) {
      state.connectionCallbacks.forEach((connectionCallback) => connectionCallback({}));
      state.isConnecting = false;
      state.isConnected = true;
    },
    connectionFailed(state) {
      state.connectionCallbacks
        .forEach((connectionCallback) => connectionCallback({ error: ChatError.ConnectionFailed }));
    },
    connectionClosed(state) {
      state.isConnected = false;
    },

    /**
     * The connection has been lost.
     */
    connectionLost(state) {
      state.isConnected = false;
    },
    /**
     * The session has been lost.
     */
    sessionLost(state) {
      Object.keys(state.chats).forEach((target) => {
        if (state.chats[target].alreadyConnected) {
          state.chats[target].alreadyConnected = false;
        }
      });
      state.isListeningToPersonalMessages = false;
    },

    /**
     * The connection has been recovered.
     */
    connectionRecovered(state) {
      state.isConnected = true;
      state.reconnectionQueue = [];
      state.recoverSessionHandlers = [];
    },

    /**
     * The handlers will be called when the connection is recovered. It can be used for rejoining rooms
     * or updating listeners.
     */
    addRecoverSessionHandler(state, { handler }) {
      state.recoverSessionHandlers.push(handler);
    },
    /**
     * Add a message to the queue that will be resend when we regain connection and session.
     */
    addToReconnectionQueue(state, message) {
      state.reconnectionQueue.push(message);
    },

    setIsListeningToPersonalMessages(state, isListeningToPersonalMessages) {
      state.isListeningToPersonalMessages = isListeningToPersonalMessages;
    },

    /**
     * Clears the state of the given room
     *
     * @param {*} state
     * @param {String} targetName The name of the target. If a room, then the full name of the room,
     * with the roomType included. Otherwise the user id.
     */
    clearChat(state, targetName) {
      Vue.set(
        state.chats,
        targetName,
        {
          chat: [],
          messages: {},
          // TODO: right now contactIds store all the contacts that have contributed to the conversarion
          // A refactor is needed to store in a different array the contacts that are currently in the room.
          contactIds: [],
          alreadyConnected: false,
        },
      );
    },

    roomJoined(state, targeName) {
      state.chats[targeName].alreadyConnected = true;
    },

    /**
     * Add Message
     */
    addMessage(
      state,
      {
        targetName,
        senderId,
        message,
        timestamp,
        messageID,
        isPending,
        isFailure,
        addAtTheBeggining,
      },
    ) {
      const chat = addAtTheBeggining
        ? [messageID, ...(state.chats[targetName] || {}).chat]
        : [...(state.chats[targetName] || {}).chat, messageID];

      Vue.set(
        state.chats,
        targetName,
        {
          ...(state.chats[targetName] || {}),
          chat,
          messages: {
            ...(state.chats[targetName] || {}).messages,
            [messageID]: {
              message,
              time: new Date(timestamp).toISOString(),
              senderId,
              isPending: !!isPending,
              isFailure: !!isFailure,
            },
          },
        },
      );
    },

    /**
     * Update Message
     */
    updateMessage(state, {
      targetName,
      messageID,
      actualMessageID,
      isPending,
      isFailure,
    }) {
      const message = state.chats[targetName].messages[messageID];
      const targetMessageID = actualMessageID || messageID;

      Vue.set(
        state.chats[targetName].messages,
        targetMessageID,
        { ...message, isPending: !!isPending, isFailure: !!isFailure },
      );

      if (actualMessageID) {
        Vue.delete(state.chats[targetName].messages, messageID);
        const index = state.chats[targetName].chat.indexOf(messageID);
        Vue.set(state.chats[targetName].chat, index, actualMessageID);
      }
    },

    /**
     * Clears the list of contacts of a target.
     */
    clearContacts(state, { targetName }) {
      Vue.set(state.chats[targetName], 'contactIds', []);
    },

    /**
     * Adds contacts information.
     * If targetName is given, the it adds those contacts to the list of contacts or that target.
     * @param {Object} state
     * @param {String} payload.targetName Optional. If given, the contact's ids will be added to the contacts list.
     * @param {Array<{
     *     key: string;
     *     username: string;
     *     headline: string;
     *     name: string;
     *     surname: string;
     *     backgroundUrl: string;
     * }>} payload.contacts The list of contacts..
     */
    addContacts(state, { targetName, contacts }) {
      contacts.forEach((contact) => {
        if (targetName) {
          state.chats[targetName].contactIds.push(contact.key);
        }
        Vue.set(
          state.contacts,
          contact.key,
          {
            ...contact,
          },
        );
      });
    },

    setIndividualChatUser(state, {user, username, room}) {
      state.individualChatUser = user;
      state.individualChatUsername = username;
      state.room = room;
    },

    // Global individual chat
    openIndividualChat(state, { user, username, room }) {
      state.individualChatUser = user;
      state.individualChatUsername = username;
      state.room = room;
      state.isIndividualChatVisible = true;
    },
    closeIndividualChat(state) {
      state.isIndividualChatVisible = false;
      state.individualChatUser = null;
      state.room = null;
    },
  },
  actions: {
    /**
     * Connect to chat service.
     *
     * @param {*} ctx Vuex Context
     * @param {Boolean} config.forceReconnection If false (the default) the connection to the service will not happend
     * if it was already openned by a diferent client (component). Otherwise it will be forced.
     * @param {CallableFunction} config.callback Function to be called once the connection gets stablished. It will be
     * called immidiatly if it's already connected.
     */
    async connect(ctx, { forceReconnection, callback }) {
      ctx.commit('addConnection', { callback, forceReconnection });

      if (ctx.state.isConnected) {
        callback();
        return;
      }

      if (ctx.state.isConnecting) {
        // do not try to connect again
        return;
      }
      try {
        ctx.commit('connectionPending');
        if (ctx.state.connections === 1 || forceReconnection) {
          await chatService.connect();

          /**
           * It reports that I lost the connection and I can not resolve any calls.
           * The chat service will try to connect 5 times to regain session.
           */
          chatService.client.addLostConnectionEventHandler(() => ctx.commit('connectionLost'));
          /**
           * I logged out and the status is zero, I have to reconnect to everything.
           * Each user of the module is responsable of subscribing to this mutation.
           */
          chatService.client.addLostSessionEventHandler(() => ctx.commit('sessionLost'));
          /**
           * The connection is resumed, if I received 'lost-session' before this I am at zero,
           * otherwise I recovered and I fine to go on.
           */
          chatService.client.addRecoveredConnectionEventHandler(() => ctx.dispatch('connectionRecovered'));
        }

        ctx.commit('connectionSuccessfull');
      } catch {
        ctx.commit('connectionFailed');
      }
    },
    async connectionRecovered({ state, commit, dispatch }) {
      // call all the handlers so that all the components can rejoin their rooms.
      await Promise.all(state.recoverSessionHandlers.map((handler) => handler()));

      // copy the array of messages that need to be resend
      const reconnectionQueue = [...state.reconnectionQueue];
      // clear the array and mark state as connected again
      commit('connectionRecovered');
      // resend all messages
      reconnectionQueue.forEach((message) => dispatch('sendMessage', message));
    },

    /**
     * Joins a room. Returns it's full-name.
     *
     * @param {*} ctx The context
     * @param {String} room The name of the room
     * @param {RoomType} roomType The type of the room
     * @returns The full name of the room, with roomType included
     */
    async joinRoom(ctx, { room, roomType }) {
      let fullRoomName = `${roomType}-${room}`;
      if (!ctx.state.chats[fullRoomName]) {
        ctx.commit('clearChat', fullRoomName);
      }

      const { alreadyConnected } = ctx.state.chats[fullRoomName];

      if (!alreadyConnected) {
        fullRoomName = await chatService.joinRoom(
          room,
          roomType,
        );
        ctx.commit('roomJoined', fullRoomName);

        chatService.addNewRoomMessageListener(fullRoomName, (event) => {
          ctx.dispatch('handleRoomMessageEvent', event);
        });
        chatService.addNewUserInRoomListener(fullRoomName, (event) => {
          ctx.dispatch('handleListUserEvent', { targetName: fullRoomName, event });
        });
      }

      return { fullRoomName, alreadyConnected };
    },

    async initPersonalChat(ctx, { user }) {
      if (!ctx.state.chats[user.userKey]) {
        ctx.commit('clearChat', user.userKey);
      }

      if (!ctx.state.isListeningToPersonalMessages) {
        ctx.commit('setIsListeningToPersonalMessages', true);

        await chatService.chatProxy.listenPersonalMessages(ctx.rootGetters.currentCollective.key);
        chatService.chatProxy.addListener('message', (event) => {
          ctx.dispatch('handleUserMessageEvent', event);
        });
      }

      ctx.commit('clearContacts', { targetName: user.userKey });
      ctx.commit('addContacts', { targetName: user.userKey, contacts: [user] });
    },

    /**
     * Lists the users in a room.
     *
     * @param {String} room The room
     * @param {'collective'|'group'} roomType The room type
     * @param {Function} handleUsersEvent To be called when a new user is added.
     * @returns The array of users.
     */
    async listRoomUsers(ctx, { room, roomType, fullRoomName }) {
      const contacts = await chatService.listUsers(
        room,
        roomType,
      );

      ctx.commit('clearContacts', { targetName: fullRoomName });
      ctx.commit('addContacts', { targetName: fullRoomName, contacts });

      return fullRoomName;
    },

    /**
     * Gets the old messages from a conversation with another user.
     *
     * @param {String} userID The user whose conversation I want to load
     * @param {Date} beforeDate Before the given date.
     * @param {number} count The limit of the fetch
     * @returns The array of messages.
     */
    async getDirectMessages(
      ctx,
      {
        userID,
        beforeDate,
        count,
      },
    ) {
      const oldMessages = await chatService.chatProxy.getDirectMessages(
        ctx.rootGetters.currentCollective.key,
        userID,
        beforeDate,
        count,
      );

      await ctx.dispatch('storeOldMessages', { oldMessages, targetName: userID });

      return oldMessages;
    },

    /**
     * Gets the old messages from a room.
     *
     * @param {String} room The room
     * @param {'collective'|'group'} roomType The room type
     * @param {Date} beforeDate Before the given date.
     * @param {number} count The limit of the fetch
     * @returns The array of messages.
     */
    async getRoomMessages(
      ctx,
      {
        room,
        roomType,
        fullRoomName,
        beforeDate,
        count,
      },
    ) {
      const oldMessages = await chatService.getRoomMessages(
        room,
        roomType,
        beforeDate,
        count,
      );

      await ctx.dispatch('storeOldMessages', { oldMessages, targetName: fullRoomName });

      return oldMessages;
    },

    /**
     * Store the old messages in the state, fetching contact information if needed.
     *
     * @param {String} targetName The targe of the messages.
     * @param {Date} beforeDate Before the given date.
     * @param {number} count The limit of the fetch
     * @returns The array of messages.
     */
    async storeOldMessages(
      ctx,
      {
        targetName,
        oldMessages,
      },
    ) {
      const sortedContacts = oldMessages
        .sort(({ timestamp: timestamp1 }, { timestamp: timestamp2 }) => (timestamp2 - timestamp1));

      const newContacts = sortedContacts
        .map(({ senderID }) => senderID)
        .reduce((ids, senderID) => {
          if (!ctx.state.contacts[senderID] && !ids.includes(senderID)) return [...ids, senderID];
          return ids;
        }, []);
      if (newContacts.length) {
        await ctx.dispatch('fetchContacts', { targetName, contactIds: newContacts });
      }

      sortedContacts
        .forEach((message) => {
          if (!ctx.state.contacts[message.senderID] && !newContacts.includes(message.senderID)) {
            newContacts.push(message.senderID);
          }

          ctx.commit('addMessage', {
            targetName,
            senderId: message.senderID,
            message: message.message,
            messageID: message.messageID,
            timestamp: message.timestamp,
            addAtTheBeggining: true,
          });
        });

      return oldMessages;
    },

    /**
     * Handle Room Message Event
     */
    async handleRoomMessageEvent(ctx, {
      target,
      origin,
      message,
      messageID,
      timestamp,
    }) {
      if (!ctx.state.contacts[origin]) {
        // Beware: this if block shouldn't be necesary, as a new commer user should be fetched on the
        // listener 'addNewUserInRoomListener', but it doesn't seem to be working.
        await ctx.dispatch('fetchContacts', {
          targetName: target,
          contactIds: [origin],
        });
      }
      ctx.dispatch('handleMessageEvent', {
        targetName: target,
        senderId: origin,
        message,
        messageID,
        timestamp,
      });
    },

    /**
     * Handle User Message Event
     */
    async handleUserMessageEvent(ctx, {
      origin,
      message,
      messageID,
      timestamp,
    }) {
      ctx.dispatch('handleMessageEvent', {
        targetName: origin, // target must be the user I receive it from
        senderId: origin,
        message,
        messageID,
        timestamp,
      });
    },

    /**
     * Handle Message Event
     */
    async handleMessageEvent(ctx, {
      targetName,
      senderId,
      message,
      messageID,
      timestamp,
    }) {
      ctx.commit('addMessage', {
        targetName,
        senderId,
        message,
        messageID,
        timestamp,
      });
    },

    /**
     * It expects the full and up-to-date list of users in the room.
     */
    async handleListUserEvent(ctx, { targetName, event: { users: contacts } }) {
      ctx.commit('addContacts', { targetName, contacts });
    },

    /**
     * Fetches the contacts for the given contactIds and adds them to the store.
     */
    async fetchContacts(ctx, { targetName, contactIds }) {
      const contacts = await Promise.all(
        contactIds.map((contactId) => chatService.chatProxy.getUserInfo(
          contactId,
          ctx.rootGetters.currentCollective.key,
        )),
      );

      ctx.commit('addContacts', { targetName, contacts });
    },

    /**
     * Sends message to a target
     */
    async sendMessage(
      ctx,
      {
        target,
        targetType,
        targetName,
        senderId,
        timestamp,
        message,
        messageID, // only present if it's a retry
      },
    ) {
      const pendingMessageID = messageID || `pending-message-${uuidv4()}`;

      if (!messageID) {
        ctx.commit('addMessage', {
          targetName,
          senderId,
          message,
          messageID: pendingMessageID,
          timestamp,
          isPending: true,
        });
      }

      if (!ctx.state.isConnected) {
        ctx.commit('addToReconnectionQueue', {
          target,
          targetType,
          targetName,
          senderId,
          timestamp,
          message,
          messageID: pendingMessageID,
        });

        return;
      }

      try {
        const actualMessageID = (await chatService.sendMessage(
          ctx.rootGetters.currentCollective.key,
          target,
          targetType,
          message,
        ));

        ctx.commit('updateMessage', {
          targetName,
          messageID: pendingMessageID,
          actualMessageID,
          isPending: false,
          isFailure: false,
        });
      } catch (error) {
        if (error === 'Client isn\'t connected') {
          // Try to send the messages again after reconnection
          ctx.commit('addToReconnectionQueue', {
            target,
            targetType,
            targetName,
            senderId,
            timestamp,
            message,
            messageID: pendingMessageID,
          });

          return;
        }

        // Mark message with my messageID as failed. TODO: Give possibility of resend?
        ctx.commit('updateMessage', {
          targetName,
          messageID: pendingMessageID,
          isPending: false,
          isFailure: true,
        });
      }
    },

    /**
     * Closes the socket connection for the chat
     */
    closeConnection(ctx, { callback }) {
      if (ctx.state.connections === 1) {
        chatService.close();

        // clear all chat rooms and users.
        Object.keys(ctx.state.chats).forEach((targetName) => {
          ctx.commit('clearChat', targetName);
        });
        ctx.commit('connectionClosed');
      }

      ctx.commit('removeConnection', { callback });
    },

    // Global individual chat
    openIndividualChat({ commit }, { user, username, room }) {
      commit('openIndividualChat', { user, username, room });
    },
    closeIndividualChat({ commit }) {
      commit('closeIndividualChat');
    },

    openChatWindow({ commit }, { user, room }) {
      commit('ADD_CHAT_WINDOW', { user, room });
    },
    closeChatWindow({ commit }, room) {
      commit('REMOVE_CHAT_WINDOW', room);
    },
  },
};
