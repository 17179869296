export const CHAT_STORE_MODULE_NAME = 'chat';

export const CHAT_ACTIONS = {
  connect: `${CHAT_STORE_MODULE_NAME}/connect`,
  connectionRecovered: `${CHAT_STORE_MODULE_NAME}/connectionRecovered`,
  handleMessageEvent: `${CHAT_STORE_MODULE_NAME}/handleMessageEvent`,
  joinRoom: `${CHAT_STORE_MODULE_NAME}/joinRoom`,
  initPersonalChat: `${CHAT_STORE_MODULE_NAME}/initPersonalChat`,
  listRoomUsers: `${CHAT_STORE_MODULE_NAME}/listRoomUsers`,
  getRoomMessages: `${CHAT_STORE_MODULE_NAME}/getRoomMessages`,
  getDirectMessages: `${CHAT_STORE_MODULE_NAME}/getDirectMessages`,
  sendMessage: `${CHAT_STORE_MODULE_NAME}/sendMessage`,
  closeConnection: `${CHAT_STORE_MODULE_NAME}/closeConnection`,

  // Global individual chats
  openIndividualChat: `${CHAT_STORE_MODULE_NAME}/openIndividualChat`,
  closeIndividualChat: `${CHAT_STORE_MODULE_NAME}/closeIndividualChat`,

  // Global individual chat windows
  openChatWindow: `${CHAT_STORE_MODULE_NAME}/openChatWindow`,
  closeChatWindow: `${CHAT_STORE_MODULE_NAME}/closeChatWindow`,
  setActiveChat: `${CHAT_STORE_MODULE_NAME}/setActiveChat`,
};

export const CHAT_GETTERS = {
  chat: `${CHAT_STORE_MODULE_NAME}/chat`,
  contacts: `${CHAT_STORE_MODULE_NAME}/contacts`,
  contactsInformationMap: `${CHAT_STORE_MODULE_NAME}/contactsInformationMap`,

  // Global individual chats
  isIndividualChatVisible: `${CHAT_STORE_MODULE_NAME}/isIndividualChatVisible`,
  room: `${CHAT_STORE_MODULE_NAME}/room`,
  individualChatUser: `${CHAT_STORE_MODULE_NAME}/individualChatUser`,
  individualChatUsername: `${CHAT_STORE_MODULE_NAME}/individualChatUsername`,
};

export const CHAT_MUTATIONS = {
  clearChat: `${CHAT_STORE_MODULE_NAME}/clearChat`,
  sessionLost: `${CHAT_STORE_MODULE_NAME}/sessionLost`,
  addRecoverSessionHandler: `${CHAT_STORE_MODULE_NAME}/addRecoverSessionHandler`,
};
