// import Bugsnag from '@bugsnag/js';
import $services from '@/config/container';
import Service from '@/config/service-identifiers';
import Vue from "vue";

import { getLoggedUser } from '@/services/backend/myself/myselfService'

export default {

  state: {
    user: {},
    roles: [],
    member: {},
  },
  getters: {
    loggedUser: ({ user }) => user,
    loggedMember: ({ member }) => member,
    loggedMemberRoles: ({ roles }) => roles,
  },
  mutations: {
    setLoggedUser(state, { user, member, roles }) {
      state.user = user ? user : {};
      state.member = member ? member : {};
      state.roles = roles ? roles : [];
    },
    updateUser(state, partialUser) {
      state.user = {
        ...state.user,
        ...partialUser,
      };
    },
    logout(state) {
      state.user = {};
      state.member = {};
    },
  },
  actions: {
    /**
     * Fetch the logged user information
     */
    async fetchLoggedUser2({ commit }, params) {
      // console.log('fetchLoggedUser2 params: ', params)
      
      // Normalize communitySlug if it's an object
      if (params && params.communitySlug) {
        if (typeof params.communitySlug === 'object' && params.communitySlug !== null) {
          console.log('Normalizing communitySlug object in fetchLoggedUser2:', params.communitySlug);
          params.communitySlug = params.communitySlug.slug || params.communitySlug.id || '';
          console.log('Normalized to:', params.communitySlug);
        }
        
        // Check for invalid slugs
        if (!params.communitySlug || params.communitySlug === 'error' || params.communitySlug === 'undefined') {
          console.log('Invalid communitySlug detected in fetchLoggedUser2:', params.communitySlug);
          params.communitySlug = null;
        }
      }
      
      try {
        const { data } = await getLoggedUser(params)
        // console.log('getLoggedUser (response.data): ', data)
        commit('setLoggedUser', data);
        // return response.data.user;
      } catch (error) {
        // If user does not have authorization to enter, delete cookies go to login
        // console.log('failed getting user, redirecting to login...');

        // Remove cookies
        Vue.$cookies.remove('lastUrl');
        Vue.$cookies.remove('registeredTo');
        Vue.$cookies.remove('accessToken');

        // Redirect
        let route = '/';
        if(process.env.VUE_APP_CUSTOMER === 'nexos') {
          // console.log('isNexos');
          route = process.env.VUE_APP_URL + '/' + process.env.VUE_APP_MAIN_SPACE_SLUG + '/auth/login';
          // console.log('userData.js - Redirecting to Nexos Login:', route);
          window.location.href = route;
        } else {
          // console.log('is NOT Nexos');
        }
        // console.log('route', route);
        // this.$router.push(route);
        
        if (error.response?.status === 401) {
          return 401;
        }
        return error.response;
      }
    },
    async fetchLoggedUser({ commit, getters, dispatch }) {
      // console.log('fetchLoggedUser')
      let communitySlug = window.location.pathname.split('/')[1];
      
      // Better handle different types of communitySlug
      if (typeof communitySlug === 'object' && communitySlug !== null) {
        console.log('Normalizing communitySlug object in fetchLoggedUser:', communitySlug);
        communitySlug = communitySlug.slug || null;
      }
      
      // Handle invalid values
      if (!communitySlug || communitySlug === 'error' || communitySlug === 'undefined'){
        console.log('Invalid communitySlug detected in fetchLoggedUser:', communitySlug);
        communitySlug = null;
      }
      
      try {
        const response = await $services[Service.BackendClient].get(
          '/myself', 
          communitySlug && communitySlug != 'my' ? { params: { communitySlug } } : ''
        );
        // console.log('getLoggedUser (responseOLD): ', response)
        commit('setLoggedUser', response.data);
        return response.data.user;
      } catch (error) {
        // If user does not have authorization to enter, delete cookies go to login
        if (error.response.status === 401) {
          return 401;
        }
        // if (Bugsnag.notify?.error) {
        //   Bugsnag.notify.error({
        //     title: 'Error',
        //     message: error.response.data.message,
        //   });
        // }

        return null;
      }
    },

    async updateUserLocale({ commit, getters }, { languageCode }) {
      await $services[Service.BackendClient].put('users', {
        userKey: getters.loggedUser.key,
        languageCode,
      });
      commit('updateUser', { languageCode });
    },

    // clearCookiesAndRedirectLogin() {
    //   // If user does not have authorization to enter, delete cookies go to login
    //   console.log('failed getting user, redirecting to login...');

    //   // Remove cookies
    //   Vue.$cookies.remove('lastUrl');
    //   Vue.$cookies.remove('registeredTo');
    //   Vue.$cookies.remove('accessToken');

    //   // Redirect
    //   let route = '/';
    //   if(process.env.VUE_APP_CUSTOMER === 'nexos') {
    //     const nexosSpaceUrl = process.env.VUE_APP_URL + '/' + process.env.VUE_APP_MAIN_SPACE_SLUG + '/auth/login';
    //     console.log('userData.js - Redirecting to Nexos Login:', nexosSpaceUrl);
    //     window.location.href = nexosSpaceUrl;
    //   } else {

    //   }
    //   this.$router.push(route);

    //   return route;
    // }
  },
};
